@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.OnlineAppointment {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
}

.StepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.InfoText {
  color: colors.$green;
  margin-top: 2rem;
  margin-bottom: 3rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
}

.ButtonContainer {
  margin-top: 3rem;
  margin-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
