.Input {
  border: none;
  font-family: 'Montserrat-Regular', sans-serif;
  outline: none;
  font-size: 16px;
  line-height: 1;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}

.TextArea {
  font-size: 16px;
  border: none;
  font-family: 'Montserrat-Regular', sans-serif;
  outline: none;
}
