@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.ReplacePreparation {
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  h3 {
    text-align: center;
    color: colors.$green;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .info-text {
    text-align: center;
    margin-bottom: 1rem;
  }

  .question {
    text-align: center;
    font-weight: 700;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .button-container {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .button {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }

  .preparations-questions {
    .replace-question {
      background: colors.$gray4;
      padding: 2rem;
      margin-bottom: 6px;

      .checkbox {
        margin-bottom: 0;
      }

      .textarea-wrapper {
        margin-top: 1rem;
      }
    }
  }
}

@media (max-width: $mobile-max) {
  .ReplacePreparation {
    .preparations-questions {
      margin-left: -32px;
      margin-right: -32px;
    }
  }
}
