@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';
@import '../OnlineFirstAppointment.module.scss';


.StepThreeOnlineFirstAppointment {
  .AppointmentInfoContainer {
    margin-bottom: 2.5rem;

    .AppointmentInfoLabel {
      margin-bottom: 0.3rem;
      text-align: center;
    }

    .AppointmentInfoDescription {
      font-weight: 600;
      text-align: center;
    }
  }

  .BackButton {
    margin-top: 1.5rem;
  }
}
