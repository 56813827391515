.UpdatePersonalData {
  div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    min-width: 0;
  }

  .input-container {
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .save-error {
    margin-top: 10px;
    color: #ff0000;
    position: static !important;
  }

  .button-container {
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
  }
}

@media (min-width: 480px) {
  .UpdatePersonalData {
    .input-container:first-of-type {
      flex: 1;
    }
  }
}
@media (min-width: 768px) and (max-width: 1279.98px) {
  .UpdatePersonalData {
    .container {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media (min-width: 1280px) {
  .UpdatePersonalData {
    .container {
      max-width: 650px;
      margin-left: auto;
      margin-right: auto;
    }

    .email-field-container {
      max-width: 650px;
    }
  }
}
