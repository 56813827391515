@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.Container {
  margin: 0 auto;
  max-width: 650px;
  text-align: center;

  h3 {
    font-size: 1.5rem;
    color: colors.$green;
  }

  .IconContainer {
    margin-bottom: 4rem;
    margin-top: 7rem;
    text-align: center;
  }
}


.HiddenButton {
  position: absolute;
  width: 3rem;
  height: 3rem;
  background-color: white;
  left: 0;
  bottom: 0;
}