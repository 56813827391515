@use '../../../styles/variables/colors';

.productSelection {
    align-items: center;
    justify-content: center;
    height: auto;
    width: 100%;
    margin-bottom: 55px;
    padding: 0 20px;

    .heading-wrapper {
        margin-top: 100px;
        margin-bottom: 80px;
    }

    .heading3 {
        font-size: 20px;
        font-weight: bold;
        margin-top: 20px;
        margin-bottom: 20px;
        color: colors.$green;
        text-align: center;
    }

    .heading4 {
        font-size: 16px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 0;
        text-align: center;
    }

    .productlist {
        width: 100%;
        padding: 0;
        padding-left: 55px;
        padding-right: 55px;
        list-style-type: none;
    }

    .productlistitem {
        padding: 10px;
        padding-top: 17px;
        padding-bottom: 17px;
        background-color: #fff;
        border-radius: 4px;
        display: flex;
        align-items: center;
        /* Vertically centers checkbox and details */
        justify-content: flex-start;
        /* Aligns items to the start */
        border-bottom: 1px solid #ccc;
        cursor: pointer;
        position: relative;

        &:last-child {
            border-bottom: none;
        }

        .product-details {
            display: flex;
            flex-direction: column;
            margin-left: 15px;
            /* Space between checkbox and product details */
        }

        .product-name {
            font-weight: bold;
            margin-bottom: 5px;
        }

        .product-cultivar {
            font-weight: 500;
            color: #000;
        }

        .product-genetic {
            font-weight: 500;
            color: #888;
            position: absolute;
                right: 10px;
                top: 17px;
        }
    }

    .input-field-wrapper {
        margin-top: 80px;
        padding-left: 140px;
        padding-right: 140px;
        justify-content: center;
        align-items: center;

        .input-field {
            margin-top: 30px;
        }

        .input-button {
            margin: auto;
            margin-top: 60px;
            padding-left: 50px;
            padding-right: 50px;
            width: 150px;
        }
    }
}