@use '../../../styles/variables/colors';

.DoctorStamp {
  margin-top: 0.5rem;
  white-space: pre;
  line-height: 2;
}
li {
  margin-bottom: 15px;
}

.heading {
  text-align: left !important;
  margin-top: 38px !important;
  margin-bottom: 20px !important;
}