@use '../../../styles/variables/colors';

.Documents {
  display: flex;
  flex-direction: column;

  .container {
    width: 100%;
    max-width: 650px;
    margin: 20px auto 60px;

    .description {
      text-align: center;
      h3 {
        margin-bottom: 20px;
      }
      li {
        margin-bottom: 14px;
      }
    }

    .documents {
      margin-top: 35px;
    }

    .noDocumentsUpladed {
      text-align: center;
      color: colors.$gray5;
      font-style: italic;
      margin-bottom: 3rem;
      margin-top: 3rem;
    }

    .upload-button-container {
      margin-top: 4rem;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
    }

    .back-to-dashboard-container {
      margin-top: 2rem;
      text-align: center;
    .back-to-dashboard-btn {
      display: inline-block;
    }
  }
  }

  .prescriptions {
    h3 {
      margin-top: 45px;
      margin-bottom: 20px;
    }
  }
}
