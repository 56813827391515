@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.ResetPassword {
  display: flex;
  flex-direction: column;

  .container {
    width: 100%;
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;

    .info {
      margin-bottom: 20px;
    }

    .password-fields {
      margin-bottom: 45px;

      .password-field {
        margin-bottom: 20px;

      }
    }

    .error {
      margin-top: 4px;
      color: colors.$red;
    }

    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }
}
