@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.PremiumPharmacy {
  max-width: 650px;
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 5px;
  margin: 5px auto 4rem;

  .image {
    margin-top: 15px;
    margin-bottom: 15px;
    flex: 1;
  }
}

.Details {
  width: 100%;

  .description {
    margin: 1rem 0 3rem 0;
    font-size: 16px;
    line-height: 30px;
  }

}

@media (max-width: $tablet-max) {
  .Details {
    .button {
      align-self: center;
    }
  }
}

@media (min-width: $phablet-min) {
  .Details {
    .button {
      width: fit-content;
    }
  }
}

@media (max-width: $mobile-max) {
  .Details {
    .button-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (max-width: 360px) {
  .PremiumPharmacy {
    .premium-check {
      font-size: 12px;
      margin-top: 2px;
    }
  }
}
