@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.Error {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 4rem;

  .button-container {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
