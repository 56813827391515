@use '../../../styles/variables/colors';

.ModalText {
  * {
    font-family: 'Montserrat-Regular', 'sans-serif' !important;
  }
}

.ModalHeadline {
  font-size: 1.75em;
}

.TopBar {
  margin-top: 45px;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.ConfirmTerms {
  max-width: 650px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4rem;

  .InfoText {
    margin-bottom: 2rem;
  }

  .CheckboxAndText {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 8px;

    .Text {
      margin-left: 10px;

      .ModalOpener {
        cursor: pointer;
        display: inline;
        text-decoration: underline;
      }
    }
  }

  .ButtonContainer {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
