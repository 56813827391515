@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';


  .Container {
    max-width: 650px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 3rem;


    h3 {
      color: colors.$green;
      font-size: 17px;
      font-weight: 700;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    .PharmacyName {
      font-weight: 600;
      font-size: 1.3rem;
      margin-top: 1rem;
    }

    .PharmacyAddress {
      margin-top: 1rem;
    }
  }

