@use '../../../styles/variables/colors';

.ModalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  z-index: 2000;
}

.ContractModal {
  max-width: 720px;
  width: 100%;
  max-height: 100%;
  background: colors.$white;
  border-radius: 4px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  h1 {
    padding-left: 0;
  }

  .Content {
    overflow-y: auto;
    flex-grow: 1;
  }

  .ButtonContainer {
    border-top: 1px solid colors.$gray2;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .Text {
      margin-bottom: 2rem;
    }

    .Back {
      cursor: pointer;
      margin-top: 1.5em;
      color: colors.$green;
    }
  }
}
