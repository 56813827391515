@use '../../../styles/variables/colors';

.Button {
  display: flex;
  cursor: pointer;
  background: colors.$green;
  color: colors.$white;
  border-radius: 24px;
  border: 1px solid colors.$green;
  padding: 10px 30px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;

  &:hover {
    transition: 400ms linear;
    background: colors.$black;
    border-color: colors.$black;
  }

  &.small {
    padding: 5px 15px;
  }

  &.inlineBlock {
    display: inline-block;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.secondary {
    color: colors.$green;
    background-color: transparent;
    border: 1px solid colors.$green;

    &:hover {
      color: colors.$black;
      border-color: colors.$black;
    }
  }
}
