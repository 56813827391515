@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.IntroPageFUP {
  margin-top: 9rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .icon-container {
    margin-bottom: 3rem;
  }

  h3 {
    color: colors.$green;
    margin-top: 0;
    margin-bottom: 2rem;
    text-align: center;
  }

  .info-text {
    margin-bottom: 2rem;
    text-align: center;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 4rem;
  }
}
