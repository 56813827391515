@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';

.PharmacyDetails {
  display: flex;
  flex-direction: column;

  .container {
    max-width: 650px;
    width: 100%;
    margin: 100px auto;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .pharmacy-name {
        font-size: 20px;
        font-weight: 700;
        color: colors.$green;
        margin-bottom: 30px;
        align-self: center;
      }

      .image {
        align-self: center;
      }

      .rating {
        margin-top: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .icon {
          margin-right: 15px;

          .icon-and-text {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            svg {
              display: block;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .button-container {
      margin-top: 40px;
      margin-bottom: 60px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .contact-details {
      h4 {
        margin-top: 0;
        margin-bottom: 10px;
        color: colors.$green;
        font-size: 16px;
        font-weight: 700;
      }

      .details-row {
        border-bottom: 2px solid colors.$gray2;
        padding-top: 10px;
        padding-bottom: 10px;

        &:last-of-type {
          border-bottom: none;
        }

        .details-value {
          a {
            color: colors.$green;
          }
        }
      }
    }
    .back-button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .back-button {
        cursor: pointer;
        color: colors.$green;
      }
    }
  }
}

@media (max-width: $mobile-max) {
  .PharmacyDetails {
    .container {
      margin-top: 60px;
      margin-bottom: 60px;

      .header {
        .rating {
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          .icon {
            margin-bottom: 10px;
          }
        }
      }

      .contact-details {
        .details-row {

          .details-item {
            font-weight: 700;
          }

          .details-value {
            margin-top: 5px;
          }
        }
      }

      .back-button {
        margin-top: 40px;
      }
    }
  }
}

@media (min-width: $tablet-min) {
  .PharmacyDetails {
    .container {

      .button-container {
        margin-top: 60px;
      }

      .contact-details {
        .details-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      .back-button-container {
        margin-top: 60px;
      }
    }
  }
}
