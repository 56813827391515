@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.Container {
  margin: 0 auto;
  max-width: 650px;

  .ButtonContainer {
    margin-top: 3rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .AgreementSection {
    margin-bottom: 3rem;
  }

  .IconContainer {
    margin-bottom: 4rem;
    margin-top: 3rem;
    text-align: center;
  }

  h3 {
    margin-bottom: 1.5rem;
  }

  .AgreementContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    .Agreement {
      display: inline;
      cursor: pointer;
      text-decoration: underline;
    }
  }
  
  .ModalContent {
    * {
      font-family: 'Montserrat-Regular', sans-serif !important;
    }
  }
  
  .ModalHeadline {
    font-size: 1.75em;
  }  
}