@use '../../../../styles/variables/colors';
@import '../../../../styles/breakpoints.module.scss';

.MedicalNews {
  margin-top: 5rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  h3 {
    text-align: center;
    color: colors.$green;
    margin-bottom: 2rem;
    margin-top: 0;
  }

  .label {
    text-align: center;
    margin-bottom: 1rem;
  }

  .choices-and-textarea {
    .choices {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;

      .radio-button {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .textarea-wrapper {
      margin-top: -1rem;
      margin-bottom: 2rem;
    }
  }

  .button-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    > div {
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }  
}
