@use '../../../styles/variables/colors';
@import '../../../styles/breakpoints.module.scss';


h1 {
  margin-top: 3rem;
  margin-bottom: 2rem;
  padding-left: 10rem;
}

.AppointmentsContainer {
  width: 100%;
  margin-bottom: 3rem;
  .Appointment {
    overflow: hidden;

    padding: 1.0rem 1rem;
    margin-left: -1rem;
    margin-right: -1rem;

    display: flex;
    flex-direction: row;
    align-items: stretch;

    border-bottom: 1px solid colors.$gray3;
    transition: height 200ms linear;

    &.Selected {
      background: colors.$green;
      color: colors.$white;

      .AppointmentTime {
        color: colors.$white;
      }
      .AppointmentChevron {
        display: block;
      }
    }

    .AppointmentTime {
      font-size: 3rem;
      line-height: 3rem;
      padding-right: 1.5rem;
      color: colors.$green;
      width: 10rem;
    }

    .AppointmentDetails {
      flex-grow: 1;
      padding-top: 0.1rem;
      .AppointmentPatient {
        font-weight: 600;
      }
      .AppointmentDoctor {
        display: flex;
        align-items: center;
        margin-top: 0.1rem;
        font-weight: 400;
      }
    }

    .AppointmentChevron {
      display: none;
      padding-left: 2rem;
      border-left: 1px solid #4dc394;

      svg {
        transform: rotate(-90deg); 
        transform-origin: center;
        padding-right: 0.2rem;
      }
    }

    .AppointmentButton {
      background-color: colors.$white;
      color: colors.$green;
      margin-right: 0.5rem;
      margin-top: 0.5rem;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}