.MyAccount {
  div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    min-width: 0;
  }

  .button-container {
    justify-content: flex-start !important;
  }

  .delete-account-text {
    max-width: 650px;
  }
}

@media (min-width: 1280px) {
  .MyAccount {
    .contact-field {
      margin-right: 120px;
    }

    .container {
      max-width: 1080px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
